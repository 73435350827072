import BaseService from '@/services/BaseService'

class ReviewService extends BaseService {
  constructor() {
    super()
  }

  getReview(params = {}) {
    this.endPoint = 'api/v2/reviews/orders'
    return this.get(params).then(response => {
      return response.data
    })
  }

  // setBanners(data) {
  //     this.endPoint = 'api/v2/banners'
  //     return this.post(data).then(response => {
  //         return response.data
  //     })
  // }

  // editBanners(id, data) {
  //     this.endPoint = 'api/v2/banners'
  //     return this.postOne(id, data).then(response => {
  //         return response.data
  //     })
  // }

  // deleteBanners(id) {
  //     this.endPoint = 'api/v2/banners'
  //     return this.delete(id).then(response => {
  //         return response.data
  //     })
  // }
}

export default new ReviewService()
