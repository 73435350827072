import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import moment from 'moment'

// Base Page
import BasePage from '@/pages/Base'

// Services
import ReviewServices from '@/services/Review'

@Component({})
export default class ReviewList extends BasePage {
  constructor() {
    super()
  }

  // Table
  tableHeaders: object[] = [
    {
      text: 'Reviewer Name',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'in Review to',
      align: 'left',
      sortable: false,
      value: 'reviewable'
    },
    {
      text: 'Rate',
      align: 'left',
      sortable: true,
      value: 'point'
    },
    {
      text: 'Review',
      align: 'left',
      sortable: false,
      value: 'content'
    },
    {
      text: 'Submitted on',
      align: 'left',
      sortable: true,
      value: 'submitted_at'
    }
    // {
    //   text: 'Action',
    //   align: 'center',
    //   sortable: false,
    //   value: 'action',
    //   class: 'action-table-width'
    // }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'submitted_at',
    page: 1,
    rowsPerPage: 25,
    descending: false,
    totalItems: 0
  }
  tableLoading: boolean = true

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getReview()
  }

  async getReview() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          include: 'reviewer,reviewable'
        }
      }
      const response = await ReviewServices.getReview(opts)
      this.tableItems = []
      forEach(response.data, review => {
        const item = {
          ...review.attributes,
          reviewer: response.included.reviewer[review.attributes.created_by].attributes.name,
          email: response.included.reviewer[review.attributes.created_by].attributes.email,
          reviewable: response.included.reviewable[review.attributes.reviewable_id].attributes.order_code,
          url: `/orders/${response.included.reviewable[review.attributes.reviewable_id].attributes.id}/details`,
          submitted_on: moment(review.attributes.submitted_at).format('DD MMM YYYY')

        }
        this.tableItems.push(item)
      })
      this.tableTotalItems = response.meta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }
}
